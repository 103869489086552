import React from 'react';
import PropTypes from 'prop-types';
import { domOnlyProps, FieldBlock, DatePicker, TextBlock } from 'cccisd-redux-form-helpers';
import ClickButton from 'cccisd-click-button';
import { reduxForm } from 'redux-form';
import style from './style.css';
import Toggle from 'cccisd-toggle';
import InfoLabel from 'js/components/InfoLabel';
import classnames from 'classnames';
import moment from 'moment';
import hash from 'object-hash';

const fields = [
    'assessment',
    'assessmentName',
    'nameSuffix',
    'startDate',
    'endDate',
    'assignmentSurveys',
    'messageSubject',
    'messageBody',
    'messageBtnText',
    'messageBodyLower',
    'messageSalutation',
    'reminderBody',
    'reminderSubject',
    'reminderBtnText',
    'reminderBodyLower',
    'reminderSalutation',
    'autoSendMessage',
];

class Form extends React.Component {
    static propTypes = {
        fields: PropTypes.object,
        handleSubmit: PropTypes.func,
        submitting: PropTypes.bool,
        isEdit: PropTypes.bool,
        surveyList: PropTypes.array,
        assessment: PropTypes.string,
    };

    state = {
        assessmentStatus: null,
    };

    componentDidMount = () => {
        this.generateName();
        this.props.fields.assessment.onChange(this.props.assessment);
        if (this.props.isEdit) {
            this.setStatus();
        }
        this.props.fields.autoSendMessage.onChange(true);
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (
            prevProps.fields.startDate !== this.props.fields.startDate ||
            prevProps.fields.endDate !== this.props.fields.endDate ||
            prevProps.fields.nameSuffix !== this.props.fields.nameSuffix
        ) {
            this.generateName();
        }
    };

    setStatus = () => {
        let { startDate, endDate } = this.props.fields;

        startDate = moment(startDate.value);
        endDate = moment(endDate.value);
        const now = moment();
        if (startDate.isBefore(now) && now.isBefore(endDate)) {
            this.setState({ assessmentStatus: 'Open' });
        } else {
            this.setState({ assessmentStatus: 'Upcomming' });
        }
    };

    needsUpdate = prevProps => {
        return (
            this.props.fields.assignmentSurveys.value !== prevProps.fields.assignmentSurveys.value
        );
    };

    toggleField = field => {
        field.onChange(!field.value);
    };

    generateName = () => {
        const {
            fields: { assessmentName, startDate, endDate, nameSuffix },
            assessment,
        } = this.props;
        let sd = '';
        let ed = '';
        function formatDate(date) {
            return moment(date)
                .format('MMM YYYY')
                .toUpperCase();
        }
        if (startDate.value) {
            sd = formatDate(startDate.value);
        }
        if (endDate.value) {
            ed = formatDate(endDate.value);
        }
        if (sd && ed) {
            if (sd === ed) {
                ed = '';
            } else if (sd.split(' ')[1] === ed.split(' ')[1]) {
                sd = sd.split(' ')[0];
            }
        }
        assessmentName.onChange(
            `${assessment}: ${sd && sd}${ed && sd && '-'}${ed && ed} ${nameSuffix.value}`
        );
    };

    toggleValue = val => {
        return val ? val : false;
    };

    onFormsChange = (value, field) => {
        const newSet = this.arrayHasObject(field.value, value)
            ? field.value.filter(form => hash(form) !== hash(value))
            : [...field.value, value];
        field.onChange(newSet);
    };

    arrayHasObject = (array, object) => {
        const hashObj = hash(object);
        for (var i = 0; i < array.length; i++) {
            const item = array[i];
            if (hash(item) === hashObj) {
                return true;
            }
        }
        return false;
    };

    render() {
        const {
            fields: {
                nameSuffix,
                startDate,
                assessmentName,
                endDate,
                assignmentSurveys,
                messageSubject,
                messageBody,
                messageBtnText,
                messageBodyLower,
                messageSalutation,
                autoSendMessage,
            },
            handleSubmit,
            submitting,
            surveyList,
            isEdit,
        } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <div className={style.assessmentName}>
                    <div className={style.label}>
                        <InfoLabel
                            label="Assessment Name"
                            tooltip="The name will be automatically generated based on the start and end date."
                            color="#81d4fa"
                            hideDecorator
                        />
                    </div>
                    <div className={style.name}>{assessmentName.value}</div>
                </div>
                <div className={style.spaceBetween}>
                    <div>
                        {!isEdit && (
                            <div style={{ width: '82%' }}>
                                <TextBlock
                                    label={
                                        <InfoLabel
                                            label="Unique Identifier"
                                            tooltip="Use this to distinguish assessments with similar date ranges"
                                            optional
                                        />
                                    }
                                    field={nameSuffix}
                                />
                            </div>
                        )}
                        <div className={style.flex}>
                            {this.state.assessmentStatus !== 'Open' && (
                                <FieldBlock
                                    field={startDate}
                                    label={
                                        <InfoLabel
                                            label="Start Date"
                                            tooltip="The date on which your assessment opens and you can begin data collection. You may edit this date until it has passed."
                                        />
                                    }
                                >
                                    <div className={style.spreadEm} data-start>
                                        <DatePicker
                                            field={startDate}
                                            placeholder="Pick date & time"
                                            showTimeSelect
                                            showYearDropdown
                                        />
                                    </div>
                                </FieldBlock>
                            )}
                            <FieldBlock
                                field={endDate}
                                label={
                                    <InfoLabel
                                        label="End Date"
                                        tooltip="The date on which your assessment will close and you can no longer collect data. You may edit this date and extend your assessment. Once it closes, you cannot reopen it to add more time."
                                    />
                                }
                            >
                                <div data-end>
                                    <DatePicker
                                        field={endDate}
                                        placeholder="Pick date & time"
                                        showTimeSelect
                                        showYearDropdown
                                    />
                                </div>
                            </FieldBlock>
                        </div>
                    </div>
                    {this.state.assessmentStatus !== 'Open' && (
                        <FieldBlock
                            field={assignmentSurveys}
                            label={<InfoLabel label="Select Surveys to Administer" />}
                        >
                            {surveyList.map(survey => (
                                <div className={style.toggle} key={survey.label}>
                                    <Toggle
                                        value={this.arrayHasObject(assignmentSurveys.value, survey)}
                                        onChange={this.onFormsChange.bind(
                                            this,
                                            survey,
                                            assignmentSurveys
                                        )}
                                        label={survey.label}
                                    />
                                </div>
                            ))}
                            <div />
                        </FieldBlock>
                    )}
                </div>
                <div>
                    {!isEdit && (
                        <div>
                            <div className={style.spaceBetween}>
                                <h3>Messaging</h3>
                                <FieldBlock field={autoSendMessage}>
                                    <div className={classnames(style.marginLeft, 'checkbox')}>
                                        <Toggle
                                            value={this.toggleValue(autoSendMessage.value)}
                                            onChange={this.toggleField.bind(this, autoSendMessage)}
                                            label={
                                                <InfoLabel
                                                    className={style.labelMargin}
                                                    label="Auto send message"
                                                    tooltip="Automatically send the invite message once assessment opens?"
                                                    hideDecorator
                                                />
                                            }
                                        />
                                    </div>
                                </FieldBlock>
                            </div>
                            <TextBlock
                                label={
                                    <InfoLabel
                                        label="Subject"
                                        tooltip="The subject line in the email message for the survey"
                                        optional
                                    />
                                }
                                field={messageSubject}
                            />
                            <FieldBlock
                                field={messageBody}
                                label={<InfoLabel label="Body Content" optional />}
                            >
                                <textarea
                                    {...domOnlyProps(messageBody)}
                                    rows={5}
                                    style={{ width: '100%' }}
                                >
                                    {messageBody.value}
                                </textarea>
                            </FieldBlock>

                            <TextBlock
                                label={
                                    <InfoLabel
                                        label="Button Text"
                                        tooltip="The text on the survey link button"
                                        optional
                                    />
                                }
                                field={messageBtnText}
                            />
                            <TextBlock
                                label={<InfoLabel label="Lower Body Content" optional />}
                                field={messageBodyLower}
                            />
                            <TextBlock
                                label={
                                    <InfoLabel
                                        label="Salutation"
                                        tooltip="The last text in the email. Use it to say thanks and goodbye."
                                        optional
                                    />
                                }
                                field={messageSalutation}
                            />
                        </div>
                    )}
                </div>

                <div className={style.rightAlign}>
                    <ClickButton
                        title="Submit"
                        onClick={handleSubmit}
                        isLoading={submitting}
                        className="btn btn-success"
                    />
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const errors = {};
    // do these on the create form only - no edit
    if (!props.isEdit) {
        const now = moment();
        if (values.nameSuffix) {
            if (values.nameSuffix.length > 15) {
                errors.nameSuffix =
                    'Remove at least ' +
                    (values.nameSuffix.length - 15).toString() +
                    ' characters.';
            }
        }
        if (!values.startDate) {
            errors.startDate = 'Start date  is required';
        }
        if (!values.endDate) {
            errors.endDate = 'End date is required';
        }
        if (moment(values.endDate).isBefore(values.startDate)) {
            errors.startDate = 'Cannot start after end date';
            errors.endDate = 'Cannot end before start date';
        }
        if (moment(values.startDate).isBefore(now)) {
            errors.startDate = 'Must be in the future';
        }
        if (moment(values.dateRangeEnd).isBefore(values.dateRangeStart)) {
            errors.dateRangeStart = 'Cannot start after end date';
            errors.dateRangeEnd = 'Cannot end before start date';
        }
        if (!values.assignmentSurveys) {
            errors.assignmentSurveys = 'Please select at least one survey to administer';
        }
    }

    return errors;
};

export default reduxForm({
    form: 'orgAssessment',
    fields,
    validate,
})(Form);

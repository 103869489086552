import React, { useState } from 'react';
import SelectList from 'react-widgets/lib/SelectList';
import Button from 'cccisd-click-button';
import DetectClickLocation from 'cccisd-detect-click-location';
import IconQuestion from 'cccisd-icons/question4';
import Tooltip from 'cccisd-tooltip';
import style from './style.css';

const SelectMany = props => {
    const {
        field,
        form,
        label,
        options,
        initialExpanded,
        setPersistExpanded,
        clearPersistExpanded,
        tooltip,
        isDisabled,
    } = props;
    const [isExpanded, setIsExpanded] = useState(initialExpanded);

    function handleOnChange(val) {
        setPersistExpanded();
        form.setFieldValue(field.name, val);
    }

    let buttonLabel = <i>None selected</i>;
    if (field.value.length === 1) {
        buttonLabel = field.value[0].label;
    } else if (field.value.length > 1) {
        buttonLabel = field.value.length + ' selected';
    }

    return (
        <div className={style.selectMany}>
            <label>
                {label}
                {tooltip && (
                    <Tooltip title={tooltip}>
                        <span className="text-primary">
                            <IconQuestion spaceLeft />
                        </span>
                    </Tooltip>
                )}
            </label>
            <Button
                title={buttonLabel}
                className={'btn btn-default ' + style.expandButton}
                onClick={() => setIsExpanded(prev => !prev)}
                isDisabled={isDisabled}
            />
            <DetectClickLocation
                clickedOutside={() => {
                    clearPersistExpanded(field.name);
                    setIsExpanded(false);
                }}
            >
                {isExpanded && (
                    <div style={{ position: 'absolute' }}>
                        <Button
                            className="btn btn-xs btn-default"
                            isDisabled={field.value.length === options.length}
                            onClick={() => handleOnChange(options)}
                            title="Select All"
                        />
                        <Button
                            className="btn btn-xs btn-default"
                            isDisabled={field.value.length === 0}
                            onClick={() => handleOnChange([])}
                            title="Deselect All"
                        />
                    </div>
                )}
                <SelectList
                    multiple
                    data={options}
                    textField="label"
                    valueField="value"
                    onChange={!isDisabled && handleOnChange}
                    value={field.value}
                    style={{
                        display: isExpanded ? 'block' : 'none',
                        height: 'auto',
                        marginTop: '2.5rem',
                        position: 'absolute',
                        zIndex: 1000,
                    }}
                />
            </DetectClickLocation>
        </div>
    );
};

export default SelectMany;

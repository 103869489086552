export const fields = {
    directContact: {
        label: 'Direct Contact',
        tooltip:
            'Does the respondent have regular direct contact with or provide direct services to clients/consumers in your professional role?',
        options: [
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' },
            { value: '99', label: 'Prefer not to disclose' },
        ],
        devTag: 'registration_direct_contact',
    },

    supervisorOrManager: {
        label: 'Supervisor/Manager',
        tooltip: 'Is the respondent a supervisor or manager to employed professionals in the organization?',
        options: [
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' },
            { value: '99', label: 'Prefer not to disclose' },
        ],
        devTag: 'registration_manager',
    },

    disability: {
        label: 'Disability Status',
        tooltip:
            'Does the respondent identify as a person with a disability, including a developmental or acquired mental or physical health condition?',
        options: [
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' },
            { value: '99', label: 'Prefer not to disclose' },
        ],
        devTag: 'registration_disability',
    },

    ethnicityBipoc: {
        label: 'BIPOC Identity',
        tooltip: 'Does the respondent identify as Black, Indigenous, and/or a Person of Color?',
        options: [
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' },
            { value: '99', label: 'Prefer not to disclose' },
        ],
        devTag: 'registration_person_of_color',
    },

    lgbt: {
        label: 'LGBTQIA+',
        tooltip: 'Does the respondent identify as a member of the LGBTQIA+ community?',
        options: [
            { value: '1', label: 'Yes' },
            { value: '2', label: 'No' },
            { value: '99', label: 'Prefer not to disclose' },
        ],
        devTag: 'registration_lgbt',
    },
};

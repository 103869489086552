import React, { useState, useEffect } from 'react';
import _get from 'lodash/get';

import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import { Redirect } from 'cccisd-react-router';
import Tabs from 'cccisd-tabs';

import CurrentAssessment from './CurrentAssessment';
import ManageAssessment from './ManageAssessment';
import registrationSurveyStatusQuery from './registrationSurveyStatus.graphql';

const Fortress = window.cccisd.fortress;
const actingUser = Fortress.user.acting;

const OrgAssessment = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isRedirectToRegistration, setIsRedirectToRegistration] = useState(false);

    useEffect(() => {
        async function checkRegistrationSurvey() {
            if (['orgAdmin', 'orgTeamMember'].includes(Fortress.user.actual.role.handle)) {
                const resp = await client.query({ query: registrationSurveyStatusQuery, fetchPolicy: 'network-only' });
                const isComplete = _get(resp, 'data.roles.anyRole.assignmentProgress.completed', false) === true;
                setIsRedirectToRegistration(!isComplete);
            }

            setIsLoading(false);
        }

        checkRegistrationSurvey();
    }, []);

    if (isLoading) {
        return <Loader loading />;
    }

    // Don't let orgAdmin or orgTeamMembers do anything if they have not done
    // the registration survey (or else filters won't work on Report)
    if (isRedirectToRegistration) {
        return <Redirect to="/account/registrationSurvey" />;
    }

    const tabList = [
        { name: 'currentAssessment', title: 'Organization Assessment', content: <CurrentAssessment /> },
        { name: 'manageAssessment', title: 'Manage Assessment', content: <ManageAssessment /> },
    ];

    return (
        <div>
            <h2>Organization Assessment</h2>
            {actingUser.data_type === 'orgAdmin' ? <Tabs tabList={tabList} /> : <CurrentAssessment />}
        </div>
    );
};

export default OrgAssessment;

import React, { useState, useRef, useEffect } from 'react';
import _isEqual from 'lodash/isEqual';
import { Formik, Form, Field } from 'cccisd-formik';
import IconRight from 'cccisd-icons/arrow-right5';
import IconDown from 'cccisd-icons/arrow-down5';
import IconQuestion from 'cccisd-icons/question4';
import Tooltip from 'cccisd-tooltip';

import SelectMany from './SelectMany';
import style from './style.css';
import { fields } from './fields.js';

const Filter = ({ filter, setFilter }) => {
    const filterEl = useRef(null);
    const [lastSubmission, setLastSubmission] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [totalHeight, setTotalHeight] = useState(0);
    const [persistExpanded, setPersistExpanded] = useState(null);

    function handleResize() {
        if (filterEl && filterEl.current) {
            setTotalHeight(filterEl.current.offsetHeight + 40);
        }
    }

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [filterEl, isExpanded]);

    function handleSubmit(vals) {
        let gqlFilters = { AND: [] };

        Object.keys(fields).forEach(fieldName => {
            if (Array.isArray(vals[fieldName]) && vals[fieldName].length > 0) {
                const fieldDef = fields[fieldName];
                if (fieldDef.isMultipleDevTags) {
                    gqlFilters.AND.push({
                        OR: vals[fieldName].map(field => ({
                            eq: { field: `registrationAp.devTags.${field.devTag}`, string: '1' },
                        })),
                    });
                } else {
                    gqlFilters.AND.push({
                        in: {
                            field: `registrationAp.devTags.${fields[fieldName].devTag}`,
                            string: vals[fieldName].map(val => val.value),
                        },
                    });
                }
            }
        });

        if (gqlFilters.AND.length === 0) {
            gqlFilters = {};
        }

        setFilter(gqlFilters);
        setLastSubmission(vals);
    }

    let initialVals = {};
    Object.keys(fields).forEach(k => {
        initialVals[k] = [];
    });

    function renderCurrentFilterCount() {
        let count = 0;
        try {
            count = filter.AND.length;
        } catch (e) {
            // meh, leave it 0
        }

        if (count === 0) {
            return null;
        }

        let filteredByLabel = null;
        try {
            const filterDef = filter.AND[0].in.field;
            const filteredByDevTag = filterDef.slice(filterDef.lastIndexOf('.') + 1);
            filteredByLabel = Object.values(fields).find(f => f.devTag === filteredByDevTag).label;
        } catch (e) {
            // meh, just don't white screen
        }

        const textToShow = filteredByLabel
            ? `(Filtered by ${filteredByLabel})`
            : `(${count} filter${count === 1 ? '' : 's'} applied)`;
        return (
            <span className={style.filterCount}>
                {textToShow}
                <Tooltip title="To keep report anonymous, you may only filter by one field at a time.">
                    <IconQuestion spaceLeft />
                </Tooltip>
            </span>
        );
    }

    return (
        <div>
            <Formik onSubmit={handleSubmit} initialValues={initialVals}>
                {formikBag => {
                    const oneFilterApplied = Object.keys(formikBag.values).find(k => formikBag.values[k].length > 0);
                    const isTrueDirty = !lastSubmission ? formikBag.dirty : !_isEqual(lastSubmission, formikBag.values);
                    return (
                        <Form className={style.form} style={{ height: isExpanded ? totalHeight : '64px' }}>
                            <div ref={filterEl}>
                                <h4>
                                    <span className={style.expandFilter} onClick={() => setIsExpanded(prev => !prev)}>
                                        Filters
                                        {isExpanded ? <IconDown /> : <IconRight />}
                                    </span>
                                    {renderCurrentFilterCount()}
                                </h4>
                                <div className={style.fields}>
                                    {Object.keys(fields).map(fieldName => (
                                        <Field
                                            key={fieldName}
                                            name={fieldName}
                                            component={fieldProps => (
                                                <SelectMany
                                                    {...fieldProps}
                                                    isDisabled={oneFilterApplied && oneFilterApplied !== fieldName}
                                                    label={fields[fieldName].label}
                                                    options={fields[fieldName].options}
                                                    tooltip={fields[fieldName].tooltip}
                                                    initialExpanded={persistExpanded === fieldName}
                                                    clearPersistExpanded={fieldHandle => {
                                                        if (fieldHandle === persistExpanded) {
                                                            setPersistExpanded(null);
                                                        }
                                                    }}
                                                    setPersistExpanded={() => setPersistExpanded(fieldName)}
                                                />
                                            )}
                                        />
                                    ))}
                                </div>
                                <button
                                    type="submit"
                                    className={isTrueDirty ? 'btn btn-warning' : 'btn btn-primary'}
                                    disabled={!isTrueDirty}
                                >
                                    Apply Filters
                                </button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default Filter;

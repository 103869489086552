import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { client } from 'cccisd-apollo';
import Modal from 'cccisd-modal';
import Table from 'cccisd-graphql-table';
import Tooltip from 'cccisd-tooltip';
import classnames from 'classnames';
import Loader from 'cccisd-loader';
import { Messaging } from 'cccisd-laravel-assignment';
import AssessmentForm from './AssessmentForm';
import AssessmentFormEdit from './AssessmentFormEdit';
import surveysByHandle from './surveysByHandle.graphql';
import deploymentsByHandle from './deploymentsByHandle.graphql';
import getOrgUsersQuery from '../allGroupMembers.graphql';
import style from './style.css';
import _filter from 'lodash/filter';

import IconEdit from 'cccisd-icons/pencil5';
import IconEmail from 'cccisd-icons/bubble-lines3';
import IconPlus from 'cccisd-icons/plus';
import IconWarning from 'cccisd-icons/notification';
import IconCheck from 'cccisd-icons/checkmark';
// import IconNewTab from 'cccisd-icons/new-tab';

const Fortress = window.cccisd.fortress;
const userGroup = Fortress.user.acting.group;
const AppDefs = window.cccisd.appDefs;

const ManageAssessment = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [surveyList, setSurveyList] = useState([]);
    const [assignmentId, setAssignmentId] = useState(null);
    // const [numOrgUsers, setNumOrgUsers] = useState(null);
    const [orgUserPawnIds, setOrgUserPawnIds] = useState([]);
    const [thereIsOpenOrUpcomingAssessment, setThereIsOpenOrUpcomingAssessment] = useState(false);
    const assignmentHandle = 'orgAssessment';

    const myTable = useRef(null);

    const messageTemplate = AppDefs.app.siteContent.orgMessaging.find(
        template => template.condition === 'all_respondents' && template.messageMethod === 'email'
    );

    const messageInfo = {
        actionUrl: '%URL%',
        body: messageTemplate.messageInfo.body,
        subject: messageTemplate.messageInfo.subject,
        actionText: messageTemplate.messageInfo.actionText,
        outroLines: messageTemplate.messageInfo.outroLines,
        salutation: messageTemplate.messageInfo.salutation,
    };

    const reminderTemplate = AppDefs.app.siteContent.orgMessaging.find(
        template => template.condition === 'not_started' && template.messageMethod === 'email'
    );

    const reminderInfo = {
        actionUrl: '%URL%',
        body: reminderTemplate.messageInfo.body,
        subject: reminderTemplate.messageInfo.subject,
        actionText: reminderTemplate.messageInfo.actionText,
        outroLines: reminderTemplate.messageInfo.outroLines,
        salutation: reminderTemplate.messageInfo.salutation,
    };

    const columns = [
        {
            name: 'actions',
            label: 'Actions',
            hideInCsv: true,
            render: ({ value, row, loadData }) => (
                <div className={style.flex}>
                    {!isClosed(row.closesAt) && (
                        <Modal
                            size="large"
                            trigger={
                                <Tooltip title="Edit Assessment Dates">
                                    <button
                                        type="button"
                                        className={classnames('btn', 'btn-warning', style.btn, style.moveOver)}
                                    >
                                        <IconEdit />
                                    </button>
                                </Tooltip>
                            }
                            title={`Edit ${row.label}`}
                            afterClose={() => reloadTableData()}
                        >
                            <div />
                            <AssessmentFormEdit
                                assessment={userGroup.label || 'Assessment'}
                                initialValues={getInitialValues(row)}
                                deploymentId={row.deploymentId}
                                status={isUpcoming(row.opensAt) ? 'Upcoming' : ''}
                                loadData={loadData}
                                row={row}
                            />
                        </Modal>
                    )}
                    {!isClosed(row.closesAt) && (
                        <Modal
                            size="large"
                            trigger={
                                <Tooltip title="Messaging">
                                    <button
                                        type="button"
                                        className={classnames('btn', 'btn-primary', style.btn, style.moveOver)}
                                    >
                                        <IconEmail />
                                    </button>
                                </Tooltip>
                            }
                            afterClose={() => reloadTableData()}
                            title="Messaging"
                        >
                            <Messaging
                                backLinkText=" "
                                backLinkUrl=""
                                deploymentId={row.deploymentId}
                                projectId={1} // Grouptype id?
                                hideMessageReplacementNotes
                            />
                        </Modal>
                    )}
                    {isUpcoming(row.opensAt) && row['settings.options'].length < row['assignment.surveyList'].length && (
                        <Tooltip title="Some surveys are omitted from this assessment.">
                            <span style={{ color: 'red' }}>
                                <IconWarning />
                            </span>
                        </Tooltip>
                    )}
                </div>
            ),
        },
        { name: 'label', label: 'Name', sort: true, filter: true },
        {
            name: 'opensAt',
            label: 'Start Date',
            sort: true,
            filter: true,
            filterSettings: { type: 'date' },
            render: ({ value, row }) => value && undoTimezoneOffset(value),
        },
        {
            name: 'closesAt',
            label: 'End Date',
            sort: true,
            filter: true,
            filterSettings: { type: 'date' },
            render: ({ value, row }) => value && undoTimezoneOffset(value),
        },
        {
            name: 'assessmentStatus',
            label: 'Status',
            render: ({ value, row }) => {
                if (isOpen(row.opensAt, row.closesAt) && row.numCompleted >= row.respondentCount) {
                    value = 'Completed';
                } else if (isOpen(row.opensAt, row.closesAt)) {
                    value = 'Open';
                } else if (isClosed(row.closesAt)) {
                    value = 'Closed';
                }

                return value;
            },
        },
        {
            name: 'surveyCount',
            label: 'Surveys Included',
            filterSettings: {
                type: 'regex',
                field: 'settings',
                preparePattern: value => `"cycleType":"[^"]*${value}`,
            },
            render: ({ value, row }) => {
                return (
                    <Modal
                        trigger={
                            <a>
                                {row['settings.options'].length}
                                {' of '}
                                {row['assignment.surveyList'].length}
                            </a>
                        }
                        title="Surveys Included"
                    >
                        <div className="panel panel-default">
                            <div className="panel-body">
                                <span style={{ color: 'green' }}>
                                    <IconCheck />
                                </span>{' '}
                                = survey is included
                            </div>
                        </div>
                        <div>
                            <ul>
                                {row['assignment.surveyList'].map(survey => {
                                    return (
                                        <li key={survey.label}>
                                            {survey.label}{' '}
                                            {row['settings.options'].includes(survey.surveyHandle) && (
                                                <span style={{ color: 'green' }}>
                                                    <IconCheck />
                                                </span>
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </Modal>
                );
            },
        },
        {
            name: 'numCompleted',
            label: 'Organization Completion',
            tooltip: <div>Number of completed assessments / Total assessment respondents.</div>,
            render: ({ value, row }) => {
                const getWidth = () => {
                    if (value === 0) {
                        return 100;
                    }
                    let width = (value / row.respondentCount) * 100;
                    width < 25 ? (width = 25) : width;
                    return width;
                };
                return (
                    <div className="progress">
                        <div
                            className="progress-bar"
                            role="progressbar"
                            style={
                                value !== 0
                                    ? { width: `${getWidth()}%` }
                                    : { width: `${getWidth()}%`, backgroundColor: 'grey' }
                            }
                        >
                            {value + ' of ' + row.respondentCount}
                        </div>
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        setIsLoading(true);
        (async () => {
            await getSurveys();
            await getOrgUsers();
            setIsLoading(false);
        })();
    }, []);

    const getInitialValues = row => {
        return {
            assessment: row['settings.assessment'],
            nameSuffix: row['settings.nameSuffix'],
            assessmentName: row['settings.assessmentName'],
            assessmentType: row['settings.assessmentType'],
            assignmentSurveys: _filter(surveyList, x => row['settings.options'].includes(x.surveyHandle)),
            startDate: undoTimezoneOffsetFieldValue(row.opensAt),
            endDate: undoTimezoneOffsetFieldValue(row.closesAt),
            options: row['settings.options'],
            requireLogin: row['settings.requireLogin'],
            requirePass: row['settings.requirePass'],
            sendToUrl: row['settings.sendToUrl'],
            useEndpoint: row['settings.useEndpoint'],
            chainEmailFromPawn: row['settings.chainEmailFromPawn'],
            chainPhoneFromPawn: row['settings.chainPhoneFromPawn'],
        };
    };

    const reloadTableData = async () => {
        await myTable.current.loadData();
    };

    function isOpen(startDate, endDate) {
        const now = moment();
        const result =
            now.isAfter(moment(undoTimezoneOffsetFieldValue(startDate))) &&
            now.isBefore(moment(undoTimezoneOffsetFieldValue(endDate)));

        if (result) {
            setThereIsOpenOrUpcomingAssessment(true);
        }

        return result;
    }

    function isClosed(endDate) {
        const now = moment();
        const result = now.isAfter(moment(undoTimezoneOffsetFieldValue(endDate)));
        return result;
    }

    function isUpcoming(startDate) {
        const now = moment();
        const result = now.isBefore(moment(undoTimezoneOffsetFieldValue(startDate)));

        if (result) {
            setThereIsOpenOrUpcomingAssessment(true);
        }

        return result;
    }

    const getSurveys = async () => {
        // const { assignmentHandle } = this.props;

        const response = await client.query({
            query: surveysByHandle,
            variables: { assignmentHandle },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        });

        const assignment = response.data.flows.assignment;
        setAssignmentId(assignment.assignmentId);
        setSurveyList(assignment.surveyList);
    };

    const getOrgUsers = async () => {
        // const { assignmentHandle } = this.props;

        const response = await client.query({
            query: getOrgUsersQuery,
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        });

        // const allUsers = response.data.roles.orgAdminCount + response.data.roles.orgTeamMemberCount;
        const allUserPawnIds = response.data.roles.orgAdminList
            .map(orgAdmin => orgAdmin.pawn.pawnId)
            .concat(response.data.roles.orgTeamMemberList.map(orgTm => orgTm.pawn.pawnId));

        setOrgUserPawnIds(allUserPawnIds);
        // setNumOrgUsers(allUsers);
    };

    const undoTimezoneOffset = date => {
        // Values are stored in UTC time need to convert to local timezone
        const now = moment.utc(date).local().format('MM-DD-YYYY HH:mm');

        return now;
    };

    const undoTimezoneOffsetFieldValue = date => {
        // Values are stored in UTC time need to convert to local timezone
        return moment.utc(date).local().format('YYYY-MM-DD HH:mm:ss');
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div>
            <div style={{ marginBottom: '0.5em' }}>
                <Modal
                    trigger={
                        <>
                            <button type="button" className="btn btn-primary">
                                <IconPlus spaceRight /> Create Assessment
                            </button>
                        </>
                    }
                    title="Create New Assessment"
                    size="large"
                    afterClose={() => reloadTableData()}
                >
                    {thereIsOpenOrUpcomingAssessment ? (
                        <div className="jumbotron" style={{ padding: '3em' }}>
                            <h1>
                                <IconWarning />
                            </h1>
                            <p>
                                There is already an open or upcoming assessment. <br />
                                Please complete the current assessment to schedule a new one. You can edit the current
                                assessment to change the open and close dates.
                            </p>
                        </div>
                    ) : (
                        <AssessmentForm
                            assessment={userGroup.label || 'Assessment'}
                            orgUserPawnIds={orgUserPawnIds}
                            assignmentId={assignmentId}
                            assignmentHandle={assignmentHandle}
                            surveyList={surveyList}
                            initialValues={{
                                assessmentType: 'ongoing',
                                assignmentSurveys: surveyList,
                                messageBody: messageInfo.body,
                                messageSubject: messageInfo.subject,
                                messageBtnText: messageInfo.actionText,
                                messageBodyLower: messageInfo.outroLines,
                                messageSalutation: messageInfo.salutation,
                                reminderBody: reminderInfo.body,
                                reminderSubject: reminderInfo.subject,
                                reminderBtnText: reminderInfo.actionText,
                                reminderBodyLower: reminderInfo.outroLines,
                                reminderSalutation: reminderInfo.salutation,
                            }}
                            reloadTable={reloadTableData}
                        />
                    )}
                </Modal>
                {/* <a
                    href="https://dm0gz550769cd.cloudfront.net/providerwellbeing/69/69e0b0f0418d87c843df076f356106bf.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                >
                    <button type="button" className="btn btn-default" style={{ marginLeft: '1em' }}>
                        <IconNewTab /> More Information
                    </button>
                </a> */}
            </div>
            <Table
                ref={myTable}
                rowKey="deploymentId"
                className="table table-striped table-bordered"
                name="orgDeployments"
                orderBy="closesAt"
                isAscentOrder={false}
                columns={columns}
                graphqlVariables={{
                    assignmentHandle: 'orgAssessment',
                    orgId: `orgId: ${Fortress.user.acting.group.id}`,
                }}
                query={deploymentsByHandle}
            />
        </div>
    );
};

export default ManageAssessment;

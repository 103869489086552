import React from 'react';
import PropTypes from 'prop-types';
import axios from 'cccisd-axios';
import notification from 'cccisd-notification';
import Form from './form.js';
import moment from 'moment';

const Fortress = window.cccisd.fortress;
const Boilerplate = window.cccisd.boilerplate;

class OrgAssessmentForm extends React.Component {
    static propTypes = {
        assessment: PropTypes.string,
        isEdit: PropTypes.bool,
        assignmentId: PropTypes.number,
        assignmentHandle: PropTypes.string,
        reloadTable: PropTypes.func,
        closeModal: PropTypes.func,
        initialValues: PropTypes.object,
        onSubmit: PropTypes.func,
        surveyList: PropTypes.array,
        orgUserPawnIds: PropTypes.array,
    };

    submit = async values => {
        const orgId = Fortress.user.acting.group.id;
        await this.submitDeployment(this.props.assignmentId, orgId, values.assessmentName, values);
    };

    addRespondents = async deploymentId => {
        // add the youths to the deployment
        const response = await axios.post(
            Boilerplate.route('api.assignmentDeploymentRespondent.store', { deploymentId }),
            {
                pawnIds: this.props.orgUserPawnIds,
            }
        );

        if (response.data.errors) {
            let errors = response.data.errors;
            notification({ message: errors.roster, type: 'danger' });
            return Promise.reject(errors);
        }
    };

    applyTimezoneOffset = date => {
        // Our server uses UTC time so we need to offset the start/end date entered by client
        return moment(date).utc().format('YYYY-MM-DD HH:mm:ss');
    };

    // Set up the invite and reminder message templates for Assessment
    saveMessageTemplates = (values, deploymentId) => {
        const customEmailTemplate = {
            condition: 'all_respondents',
            messageInfo: {
                actionText: values.messageBtnText,
                actionUrl: window.cccisd.boilerplate.url('/orgAssessment'),
                subject: values.messageSubject,
                body: values.messageBody,
                outroLines: values.messageBodyLower,
                salutation: values.messageSalutation,
            },
            messageMethod: 'email',
            settings: { label: 'Invite', recipientsType: 'respondent' },
        };

        axios.post(
            Boilerplate.route('api.assignmentDeploymentMessage.store', {
                deploymentId,
            }),
            customEmailTemplate
        );

        const customReminderTemplate = {
            condition: 'not_started',
            messageInfo: {
                actionText: values.reminderBtnText,
                actionUrl: window.cccisd.boilerplate.url('/orgAssessment'),
                subject: values.reminderSubject,
                body: values.reminderBody,
                outroLines: values.reminderBodyLower,
                salutation: values.reminderSalutation,
            },
            messageMethod: 'email',
            settings: { label: 'Reminder', recipientsType: 'respondent' },
        };

        axios.post(
            Boilerplate.route('api.assignmentDeploymentMessage.store', {
                deploymentId,
            }),
            customReminderTemplate
        );
    };

    getParams = (assignmentId, orgId, name, values) => {
        return {
            assignmentId,
            handle: null,
            description: `orgId: ${orgId}`,
            hash: Math.random().toString(36).substring(2, 9),
            isOpen: false,
            label: values.assessmentName,
            roleHandle: 'orgAdmin',
            opensAt: this.applyTimezoneOffset(values.startDate),
            closesAt: this.applyTimezoneOffset(values.endDate),
            respondentsFromDcwId: null,
            settings: {
                assessment: this.props.assessment,
                assessmentName: values.assessmentName,
                org: Fortress.user.acting.group.label,
                orgId,
                startDate: values.startDate,
                endDate: values.endDate,
                nameSuffix: values.nameSuffix,
                otherAllowedRoles: ['orgTeamMember'],
                messageSubject: values.messageSubject,
                messageBody: values.messageBody,
                messageBtnText: values.messageBtnText,
                messageBodyLower: values.messageBodyLower,
                messageSalutation: values.messageSalutation,
                autoSendMessage: values.autoSendMessage || false,
                allowRetake: false,
                closeOnTarget: false,
                closedRoster: true,
                respondentConfig: 'managed',
                respondentSelection: 'orgAdmin',
                closedMessage: 'This survey is no longer accepting responses.',
                completedMessage: `<p>You have completed the assessment! Once the rest of your team completes the assessment, you will see a report here.</p>
				<p>If you or someone you know are experiencing a mental health crisis, please contact the 988 Suicide and Crisis Hotline. Call or text 988. Chat online at <a href="https://988lifeline.org">https://988lifeline.org</a></p>`,
                completionMessage: `<p>You have completed the assessment! Once the rest of your team completes the assessment, you will see a report here.</p>
				<p>If you or someone you know are experiencing a mental health crisis, please contact the 988 Suicide and Crisis Hotline. Call or text 988. Chat online at <a href="https://988lifeline.org">https://988lifeline.org</a></p>`,
                options: values.assignmentSurveys.map(survey => survey.surveyHandle),
                requireLogin: false,
                requirePass: false,
                sendToUrl: false,
                useEndpoint: false,
            },
            timepoint: '',
            type: 'group',
        };
    };

    submitDeployment = async (assignmentId, siteId, name, values) => {
        const response = await axios.post(
            Boilerplate.route('api.assignmentDeployment.store'),
            this.getParams(assignmentId, siteId, name, values)
        );

        if (response.data.errors) {
            let errors = response.data.errors;
            notification({ message: errors, type: 'danger' });
            return Promise.reject(errors);
        }

        this.addRespondents(response.data.data.id);

        this.props.closeModal();
        this.saveMessageTemplates(values, response.data.data.id);
    };

    render() {
        return (
            <Form
                assessment={this.props.assessment}
                initialValues={this.props.initialValues}
                onSubmit={this.submit}
                isEdit={this.props.isEdit}
                surveyList={this.props.surveyList}
            />
        );
    }
}

export default OrgAssessmentForm;

import React, { useState, useEffect } from 'react';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import AssessmentForm from '../AssessmentForm/form.js';
import axios from 'cccisd-axios';
import notification from 'cccisd-notification';
import moment from 'moment';
import { unflatten } from 'flat';
import surveysByHandle from '../surveysByHandle.graphql';

const Boilerplate = window.cccisd.boilerplate;

export default props => {
    const [surveyList, setSurveyList] = useState([]);
    const [loading, setLoading] = useState(true);
    const { closeModal, assessment, initialValues, deploymentId, loadData, status, row } = props;
    const data = unflatten(row);

    useEffect(() => {
        setLoading(true);
        (async () => {
            await getSurveys();
            setLoading(false);
        })();
    }, []);

    const getSurveys = async () => {
        const assignmentHandle = 'orgAssessment';

        const response = await client.query({
            query: surveysByHandle,
            variables: { assignmentHandle },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        });

        const assignment = response.data.flows.assignment;
        setSurveyList(assignment.surveyList);
    };

    const applyTimezoneOffset = date => {
        // Our server uses UTC time so we need to offset the start/end date entered by client
        return moment(date)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss');
    };
    const submit = async values => {
        if (status === 'Upcoming') {
            if (!values.startDate) {
                notification({ message: <div>Start date is required</div>, type: 'danger' });
                return;
            }
            if (!values.endDate) {
                notification({ message: 'End date is required', type: 'danger' });
                return;
            }
            if (moment(values.endDate).isBefore(values.startDate)) {
                notification({ message: 'Cannot end before start date', type: 'danger' });
                return;
            }
            if (moment(values.startDate).isBefore(moment())) {
                notification({ message: 'Start date has already passed', type: 'danger' });
                return;
            }
            if (values.assignmentSurveys.length < 1) {
                notification({ message: 'Please include at least one survey', type: 'danger' });
                return;
            }
        } else if (moment(values.endDate).isBefore(moment())) {
            notification({ message: 'End date must be in the future', type: 'danger' });
            return;
        }
        if (!values.endDate) {
            notification({ message: 'End date is required', type: 'danger' });
            return;
        }
        const assessmentName = values.assessmentName;

        let params = {
            closesAt: applyTimezoneOffset(values.endDate),
            label: assessmentName,

            // you have to rebuild entire settings blob just to change assessmentType
            settings: {
                ...data.settings,
                assessmentName,
                assessmentType: values.assessmentType,
                endDate: values.endDate,
            },
        };

        if (status === 'Upcoming') {
            params = {
                ...params,
                opensAt: applyTimezoneOffset(values.startDate),
                label: assessmentName,
                settings: {
                    ...params.settings,
                    options: values.assignmentSurveys.map(survey => survey.surveyHandle),
                },
            };
        }

        const response = await axios.put(
            Boilerplate.route('api.assignmentDeployment.update', {
                deploymentId,
            }),
            params
        );

        if (response.data.errors) {
            let errors = response.data.errors;
            notification({ message: errors, type: 'danger' });
            return Promise.reject(errors);
        }

        await loadData();
        closeModal();
    };
    if (loading) {
        return <Loader />;
    }
    return (
        <AssessmentForm
            isEdit
            assessment={assessment}
            initialValues={initialValues}
            onSubmit={submit}
            surveyList={surveyList}
        />
    );
};
